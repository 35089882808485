<template>
  <div class="class-one">
    <h3 class="title-info">Promote Yourself</h3>
    <div class="holder-subtitle">
      <div class="holder-text">
        Start building your brand with flyers, brochures, post cards, door
        hangers, and business cards to generate buzz in whatever neighborhood
        you’re targeting!
      </div>
      <b-button
        variant="primary"
        class="px-3 py-2 font-weight-bold btn-browse"
        :href="browseBtnHref"
        >Browse</b-button
      >
    </div>
  </div>
</template>

<script>
import MarketingService from "@/services/MarketingService";

export default {
  name: "NoData",
  data() {
    return {
      browseBtnHref: "",
    };
  },
  async mounted() {
    this.browseBtnHref = await MarketingService.getBrowseLink();
  },
};
</script>

<style scoped>
.h6 {
  font-size: 20px !important;
  line-height: 24px !important;
}
.p {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px;
}
.holder-text {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--BodyTextColor, #000f0c);
  flex: none;
  align-self: stretch;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.holder-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.class-one {
  background: white;
  width: 333px;
  height: 294px;
  margin: 60px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.title-info {
  /* H6 Paragraph Heading */

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;

  /* primary_text */

  color: var(--BodyTextColor, #000f0c);
}
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;

  position: static;
  width: 84px;
  height: 38px;
  left: 40px;
  top: 216px;

  /* Theme Color 1 */

  background: #525151;
  border-radius: 4px !important;
  color: white !important;

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 16px 0px;

  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 18px !important;
}

.btn-browse {
  background-color: var(--ButtonBackground, rgba(82, 81, 81, 1)) !important;
  border-color: var(--ButtonBackground, rgba(82, 81, 81, 1)) !important;
}
</style>
